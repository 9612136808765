import React from "react"

import { CartProvider } from "use-shopping-cart"

export default function Cart({ children }) {
  return (
    <CartProvider
      mode="payment"
      stripe={process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}
      currency="NZD"
      billingAddressCollection={true}
    >
      {children}
    </CartProvider>
  )
}
